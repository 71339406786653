import { useQuery } from "@tanstack/react-query"
import type { Job } from "@/database-types"
import type { ApiResponse, EnrichedJob } from "@/types"
import { api } from "@/lib/api"
import { decimalToNumber, payRangeTextContent } from "@/lib/util"
import { useEffect, useRef } from "react"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { Skeleton } from "@/components/ui/skeleton"
import { cn } from "@/lib/frontend/shadcn"
import { IconDiscountCheck, IconX } from "@tabler/icons-react"
import { Badge } from "@/components/ui/badge"
import { categories, jobCategories, jobCategoriesSections } from "@/lib/shared/categories"
import { AppcastApplyLink } from "@/components/AppcastApplyLink"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { EmployerLogo } from "@/components/EmployerLogo"

type JobPageProps = {
  job: EnrichedJob
  onClose?: () => void
  showCloseButton?: boolean
  className?: string
}

type JobPageResponseData = { job: Omit<Job, "cpc" | "cpa"> }

export const JobPage: React.FC<JobPageProps> = ({ job, onClose, showCloseButton = true, className }) => {
  const analytics = useAnalytics()
  const { data: description, isLoading } = useQuery({
    queryKey: ["job-description", job.id],
    queryFn: async ({ signal }) => {
      const resp = await api.get(`/api/jobs/${job.id}`, { signal })
      const data = await resp.json<ApiResponse<JobPageResponseData>>()

      if (!data.ok) {
        throw data
      }

      return data.job.description
    },
  })

  const { store } = job

  // I couldn't figure out the best way to have the scroll position reset when
  // clicking through job descriptions and this seemed like the least worst.
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    containerRef.current?.scrollTo(0, 0)
  }, [description])

  // Track that the job page was shown
  useEffect(() => {
    analytics.track("Job Page Shown", { jobId: job.id })
  }, [analytics, job.id])

  const uiPlacement = "jobPage"

  const jobCategoriesBySection = (section: string) => {
    const filteredJobCategoriesBySection = jobCategories.filter(
      (jobCategory) => jobCategory.section === section && job.job_categories.includes(jobCategory.value)
    )

    if (filteredJobCategoriesBySection.length === 0) {
      return null
    }

    return (
      <Card key={section} className={cn("p-3 rounded-[.625rem]")}>
        <Text weight="semibold" className={cn("m-0 mb-3")}>
          {filteredJobCategoriesBySection.length > 1 && section !== "Benefits Offered" ? section + "s" : section}
        </Text>
        <Flex className={cn("flex-wrap gap-2")}>
          {filteredJobCategoriesBySection
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((jobCategory, index) => (
              <Badge
                key={index}
                size="xs"
                outlined
                className={cn("text-gray-800 border-gray-200 font-medium bg-muted")}
              >
                {jobCategory.label}
              </Badge>
            ))}
        </Flex>
      </Card>
    )
  }

  return (
    <Flex
      direction="column"
      className={cn("bg-white gap-4 px-5 w-full overflow-y-auto overflow-x-hidden", className)}
      ref={containerRef}
    >
      <Flex gap="sm" direction="column" className={cn("sticky top-0 bg-white pt-5 pb-3 z-[100] -mx-5 px-5 border-b")}>
        <Flex gap="sm">
          <Flex justify="start" wrap="wrap">
            <EmployerLogo
              employerTitle={store.employer.title}
              employerLogoUrl={store.employer.logo_url}
              className={cn("text-xl h-14 w-14")}
            />
            {job.verified && (
              <IconDiscountCheck
                size={30}
                color="white"
                fill="#4dabf7"
                className={cn("ml-[-20px]", "mt-[36px]", "z-20")}
              />
            )}
          </Flex>

          <div className={cn("flex-1")}>
            <Flex justify="between" gap="sm" className={cn("mb-0.5")}>
              <Flex direction="column">
                <Text className={cn("md:text-lg text-base md:leading-6")} weight="semibold">
                  {job.title}
                </Text>
                <Text className={cn("text-sm")} weight="medium">
                  {store.employer.title}
                </Text>
              </Flex>
              {showCloseButton && (
                <Button className={cn("p-0 mt-0.5 h-6 w-6")} variant="transparent">
                  <IconX size={20} onClick={onClose} />
                </Button>
              )}
            </Flex>
            {categories
              .slice()
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((category) => {
                if (store.employer.categories.includes(category.value)) {
                  return (
                    <Badge
                      key={category.value}
                      size="xs"
                      outlined
                      className={cn("mr-2 mb-1 text-gray-800 border-gray-200 font-medium")}
                    >
                      {category.label}
                    </Badge>
                  )
                } else {
                  return null
                }
              })}
          </div>
        </Flex>

        {!!job.pay_min && (
          <Flex align="center" justify="between" className={cn("bg-muted p-4 rounded-md")}>
            <Text size="sm" weight="semibold" className={cn("max-xss:text-xs")}>
              {job.pay_estimated ? "Estimated Pay" : "Pay"}
            </Text>
            {job.pay_min && (
              <Text className={cn("max-xss:text-xs", "text-[#228620]")} size="sm" weight="semibold" lineClamp={1}>
                {job.pay_min || job.pay_max
                  ? payRangeTextContent(decimalToNumber(job.pay_min), decimalToNumber(job.pay_max))
                  : undefined}
              </Text>
            )}
          </Flex>
        )}
        <AppcastApplyLink
          job={job}
          fullWidth
          uiPlacement={uiPlacement}
          className={cn("bg-[#228620] text-white hover:bg-[#229620] hidden md:flex")}
          disableCpaEmailPrompt
        />
      </Flex>
      <Flex direction="col" className={cn("gap-2.5 grow md:pb-4")}>
        {jobCategoriesSections.map((section) => jobCategoriesBySection(section))}

        <Card className={cn("p-3 rounded-[10px]")}>
          <Text weight="semibold" className={cn("m-0")}>
            Job Description
          </Text>
          <Text
            asChild={!isLoading}
            size="sm"
            className={cn(
              "flex flex-col grow text-[hsl(var(--foreground))] max-w-full",
              // Styles for copy but not loader
              !isLoading && [
                "prose",
                // Hide any empty elements
                "[&_*:empty]:hidden",
                // Make h1-h6 the same size
                "[&_h1]:text-lg [&_h2]:text-lg [&_h3]:text-lg [&_h4]:text-lg [&_h5]:text-lg [&_h6]:text-lg",
                // Lists next to paragraphs and vice versa should only have one margin
                "[&_p+ul]:mt-0 [&_ul+p]:mt-0 [&_p+ol]:mt-0 [&_ol+p]:mt-0",
                // Same margin rules apply to paragraphs next to lists and paragraphs next to paragraphs
                "[&_p+p]:mb-0",
                job.source !== "workmaps" && "line-clamp-4",
              ]
            )}
            onClick={(e) => {
              // Retrieve the target element from the event
              const target = e.target as HTMLElement

              // Check if the clicked element is an <a> or within an <a>
              let anchor: HTMLAnchorElement | null = null
              if (target.tagName === "A") {
                anchor = target as HTMLAnchorElement
              } else {
                anchor = target.closest<HTMLAnchorElement>("a")
              }

              if (anchor) {
                e.preventDefault()

                analytics.track("Job Description External Link Clicked", {
                  text: anchor.innerText,
                  href: anchor.href,
                  jobId: job.id,
                  storeId: store.id,
                })

                window.open(anchor.href, "_blank")
              }
            }}
          >
            {isLoading ? (
              <Flex gap="sm" direction="column" className={cn("my-3")}>
                <Skeleton className={cn("h-3")} />
                <Skeleton className={cn("h-3")} />
                <Skeleton className={cn("h-3")} />
                <Skeleton className={cn("h-3 w-5/6")} />
              </Flex>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: description ?? "<p>No job description</p>" }}
                className="mt-2 mb-4"
              />
            )}
          </Text>

          <AppcastApplyLink
            job={job}
            uiPlacement={"jobPageDescription"}
            className={cn("no-underline w-min")}
            buttonText="Learn More"
            disableCpaEmailPrompt
          />
        </Card>
      </Flex>

      <div className={cn("bg-white sticky bottom-0 py-4 -mx-5 px-5 md:w-auto border-t block md:hidden")}>
        <AppcastApplyLink
          job={job}
          fullWidth
          uiPlacement={uiPlacement}
          className={cn("bg-[#228620] text-white hover:bg-[#229620]")}
          disableCpaEmailPrompt
        />
      </div>
    </Flex>
  )
}
