"use client"
import { Button } from "@/components/ui/button"
import { LabeledCheckbox } from "@/components/ui/checkbox"
import { Card, CardContent } from "@/components/ui/card"
import { cn } from "@/lib/frontend/shadcn"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { Textarea } from "@/components/ui/textarea"
import { useForm } from "react-hook-form"
import { useUserJobPreferences } from "@/lib/frontend/hooks/useUserJobPreferences"

const reasons = [
  {
    id: "location",
    label: "Location doesn't have the jobs I want.",
  },
  {
    id: "pay",
    label: "I'm looking for more pay.",
  },
  {
    id: "employer",
    label: "I don't want to work for this employer.",
  },
  {
    id: "application difficulty",
    label: "Application process was too difficult.",
  },
] as const

export type FeedbackPromptProps = {
  isFeedbackPromptOpen: boolean
  cancelPrompt: () => void
  feedbackId?: string | null
}

type FeedbackPromptData = {
  employer_id: string
  reasons?: string[] | undefined
  feedback?: string | undefined
}

export const FeedbackPrompt = ({ isFeedbackPromptOpen, cancelPrompt, feedbackId }: FeedbackPromptProps) => {
  const { recordNegativeEmployerFeedback } = useUserJobPreferences()
  const form = useForm<FeedbackPromptData>({
    defaultValues: {
      employer_id: "",
      reasons: [],
      feedback: "",
    },
  })

  const handleSubmit = form.handleSubmit(async (data, e) => {
    if (e) {
      cancelPrompt()
    }
    try {
      await recordNegativeEmployerFeedback.mutateAsync({ ...data, feedback_id: feedbackId ?? undefined })
    } catch (_e) {
      form.setError("root", { message: "An error occurred. Please try again." })
      return false
    }
  })

  return (
    <Card
      className={cn(
        "flex",
        "items-center",
        "cursor-pointer",
        "text-left",
        "p-2.5",
        "rounded-[.625rem]",
        !isFeedbackPromptOpen && "hidden"
      )}
      asChild
      onClick={(e) => e.stopPropagation()}
    >
      <form onSubmit={handleSubmit}>
        <CardContent className={cn("flex", "gap-2.5", "w-full")}>
          <Text weight="semibold" size="sm">
            Why did you give this employer a thumbs down?
          </Text>
          {reasons.map((reason) => {
            return (
              <Flex key={reason.id} className="gap-x-2.5 py-0.5 items-center">
                <LabeledCheckbox
                  label={reason.label}
                  fontWeight="normal"
                  // checked={currentValues?.includes(reason.id)}
                  value={reason.id}
                  onCheckedChange={(checked) => {
                    const currentValues = form.getValues("reasons") || []
                    if (checked) {
                      form.setValue("reasons", [...currentValues, reason.id])
                    } else {
                      form.setValue(
                        "reasons",
                        currentValues?.filter((value) => value !== reason.id)
                      )
                    }
                    handleSubmit()
                  }}
                  {...form.register(`reasons`)}
                />
              </Flex>
            )
          })}
          <Textarea
            placeholder="If the reasons listed above weren't available, feel free to leave a comment."
            className={cn("resize-none bg-[#f8f9fa]")}
            {...form.register("feedback")}
          />
          <Flex className="gap-2.5">
            <Button fullWidth variant="transparent" onClick={cancelPrompt}>
              Cancel
            </Button>
            <Button fullWidth variant="dark" type="submit">
              Submit
            </Button>
          </Flex>
        </CardContent>
      </form>
    </Card>
  )
}
