import { Flex } from "@/components/ui/flex"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/frontend/shadcn"
import { IconList, IconMap } from "@tabler/icons-react"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useMap } from "./MapProvider"

interface Props {
  onToggle?: (mapVisible: boolean) => void
  className?: string
}

export const MapListToggle: React.FC<Props> = ({ onToggle, className }) => {
  const { mapVisible, setMapVisible } = useMap()
  const analytics = useAnalytics()

  const onClick = () => {
    setMapVisible((prevState) => {
      const newState = !prevState
      analytics.track(newState ? "Map View Button Clicked" : "List View Button Clicked")
      onToggle?.(newState)
      return newState
    })
  }

  return (
    <Flex justify="center" className={className}>
      <Button
        variant="dark"
        className={cn("fixed", "bottom-6", "z-40", "flex", "gap-2", "px-5", "shadow-md", "h-11", "w-24")}
        onClick={onClick}
        animatePress
      >
        {mapVisible ? (
          <>
            <IconList size={20} /> <span>List</span>
          </>
        ) : (
          <>
            <IconMap size={20} /> <span>Map</span>
          </>
        )}
      </Button>
    </Flex>
  )
}
