"use client"

import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { getHighestPay, getLatLonBoxFromState } from "@/lib/util"
import { ApiJobStoreItem, ApiListJob, ApiResponse } from "@/types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { createContext, useContext } from "react"
import { useMap } from "@/components/MapProvider"
import { api } from "@/lib/api"
import { workmapsStateToApiSearchParams } from "@/lib/shared/util"
import { useIsMedium } from "@/lib/frontend/util"

export type Job = ApiListJob
export type Store = ApiJobStoreItem
export type ApiJobResponse = {
  stores: Store[]
  jobs: Job[]
}

export type EnrichedStore = Store & {
  highestPay: number
  jobIds: string[]
  urgent: boolean
  verified: boolean
  source: string
  payEstimated: boolean
  jobs: Job[]
}

export type EnrichedJob = Job & {
  store: EnrichedStore
}

export type WorkmapsJobsCatalogProviderProps = {
  catalog: ApiJobResponse
  catalogPromise?: Promise<ApiJobResponse | false>
}

export type IJobsCatalogContent = {
  jobs: EnrichedJob[]
  stores: EnrichedStore[]
}

export type IJobsCatalogContext = IJobsCatalogContent & {
  selectedJob: EnrichedJob | null
  selectedStore: EnrichedStore | null
  isLoading: boolean
}

export const WorkmapsJobsCatalogContext = createContext<IJobsCatalogContext | undefined>(undefined)
WorkmapsJobsCatalogContext.displayName = "WorkmapsJobsCatalogContext"

export function useWorkmapsJobsCatalog() {
  const context = useContext(WorkmapsJobsCatalogContext)
  if (context === undefined) {
    throw new Error("useWorkmapsJobsCatalog must be used within a WorkmapsJobsCatalogProvider")
  }
  return context
}

/**
 * This is a provider that handles the loading of all jobs, stores and employers the application uses. It reacts to the
 * state provided by the `WorkmapsFiltersProvider`.
 */
export const WorkmapsJobsCatalogProvider: React.FC<React.PropsWithChildren<WorkmapsJobsCatalogProviderProps>> = ({
  catalog,
  catalogPromise,
  children,
}) => {
  const queryClient = useQueryClient()
  const { state, dispatch } = useWorkmapsContext()
  const { map, mapVisible } = useMap()
  const isMedium = useIsMedium()

  // #region Jobs storage
  const { data: { jobs, stores } = { jobs: [], stores: [] } } = useQuery({
    queryKey: ["jobCatalog"],
    initialData: catalog,
    select: (data) => {
      const jobsByStoreId = data.jobs.reduce(
        (acc, job) => {
          if (!acc[job.store_id]) acc[job.store_id] = []
          acc[job.store_id].push(job)
          return acc
        },
        {} as Record<string, Job[]>
      )
      const enrichedStoresById = data.stores.reduce(
        (acc, store) => {
          const jobs: Job[] | undefined = jobsByStoreId[store.id]

          acc[store.id] = {
            ...store,
            highestPay: jobs ? getHighestPay(jobs) : 0,
            jobIds: jobs?.map((job) => job.id) ?? [],
            urgent: jobs?.some((job) => job.urgent) ?? false,
            verified: jobs?.some((job) => job.verified) ?? false,
            source: jobs?.[0]?.source ?? "",
            payEstimated: jobs?.some((job) => job.pay_estimated),
            jobs,
          }
          return acc
        },
        {} as Record<string, EnrichedStore>
      )
      const res = {
        jobs: data.jobs.map((job) => ({
          ...job,
          store: enrichedStoresById[job.store_id],
        })),
        stores: Object.values(enrichedStoresById),
      }

      // Set the transformed jobs and stores in the query cache so components outside the tree can use it
      queryClient.setQueryData(["jobCatalog", "transformed"], res)

      return res
    },
  })

  // If the server has sent a promise of the jobs to load, resolve it and update the jobCatalog query with it.
  useQuery({
    queryKey: ["jobCatalog", "ssr"],
    refetchOnMount: true,
    enabled: !!catalogPromise && !state.driftedFromDefaultState,
    queryFn: async () => {
      const jobCatalog = await catalogPromise

      // If the job catalog preload times out, it will return false. In that case, we need to trigger loading the rest of the
      // job catalog client side. By setting the driftedFromDefaultState to true, it will immediately fire the stores query
      // below.
      if (!jobCatalog) {
        dispatch({ driftedFromDefaultState: true })
        return
      }

      queryClient.setQueryData(["jobCatalog"], jobCatalog)
    },
  })

  // This query will load stores as the user interacts with the map and state. It is disabled until the user interacts
  // with the map/state.
  const jobsQuery = useQuery({
    queryKey: [
      "jobCatalog",
      {
        ...getLatLonBoxFromState({
          lat: state.lat,
          lng: state.lng,
          zoom: state.zoom,
          mapBounds: state.mapBounds!,
          map: map?.map,
          payMin: state.payMin,
          isMobile: isMedium,
          mapVisible,
        }),
        payMin: state.payMin,
        jobCategories: state.jobCategories,
        categories: state.categories,
        occupations: state.occupations,
        employers: state.employers,
        excludeEmployers: state.excludeEmployers,
        search: state.search,
        sortBy: state.sortBy,
        showHidden: state.showHidden,
        floor: state.floor,
        verified: state.verified,
        dynamicFloor: state.dynamicFloor,
        dynamicFloorLimit: state.dynamicFloorLimit,
        allJobs: state.allJobs,
        feeds: state.feeds,
      },
    ] as const,
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: state.driftedFromDefaultState,
    queryFn: async ({ signal, queryKey: [, params] }) => {
      const searchParams = workmapsStateToApiSearchParams(params, {
        lat0: params.lat0.toString(),
        lat1: params.lat1.toString(),
        lon0: params.lon0.toString(),
        lon1: params.lon1.toString(),
        verified: params.verified ? params.verified : false,
        limit: "300",
      })

      const response = await api.get("/api/jobs", {
        signal,
        searchParams,
      })

      if (!response.ok) throw new Error(response.statusText)

      const data = await response.json<ApiResponse<ApiJobResponse>>()

      if (!data.ok) throw new Error(data.error || "Unknown error")

      queryClient.setQueryData(["jobCatalog"], data)
      dispatch({ ...state, onNextStoresUpdate: undefined })
      state.onNextStoresUpdate?.({ ...state, stores: data.stores })

      return data
    },
  })

  const selectedJob = jobs.find((job) => job.id === state.job) ?? null
  const selectedStore = stores.find((store) => store.id === (state.storeId ?? selectedJob?.store_id)) ?? null

  return (
    <WorkmapsJobsCatalogContext.Provider
      value={{ jobs, stores, selectedJob, selectedStore, isLoading: jobsQuery.isLoading }}
    >
      {children}
    </WorkmapsJobsCatalogContext.Provider>
  )
}
