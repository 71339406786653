import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { IconShare2 } from "@tabler/icons-react"
import { ApiStoreSearchResult } from "@/types"
import { useIsMedium } from "@/lib/frontend/util"
import { Button } from "./ui/button"
import { cn } from "@/lib/frontend/shadcn"

type ShareButtonProps = {
  title: string
  text: string
  trackingProp: ApiStoreSearchResult
  url: string
}

export function ShareButton({ title, text, trackingProp, url }: ShareButtonProps) {
  const isMedium = useIsMedium()
  const analytics = useAnalytics()

  const onButtonClick = async () => {
    try {
      analytics.track("Share Button Click - Job Location", { trackingProp })
      await navigator.share({ title: title, text: text, url: url })
    } catch (e) {
      // The user can cancel sharing and that will throw an error. We don't want
      // to report that to Sentry, so we catch it here.
      console.warn("Error sharing", e)
    }
  }

  return (
    <>
      {isMedium && (
        <Button size="icon" variant="transparent" onClick={onButtonClick} className={cn("h-6 w-6 share-button")}>
          <IconShare2 size={20} className="text-[#868E96]" />
        </Button>
      )}
    </>
  )
}
