import { api } from "@/lib/api"
import type { ApiResponse } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { useState, useId } from "react"
import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { getLatLonBoxFromState } from "@/lib/util"
import { workmapsStateToApiSearchParams } from "@/lib/shared/util"

export function useSearchSuggestions() {
  const [value, setValue] = useState("")
  const { state } = useWorkmapsContext()
  // Use a unique id so that this can be used without a context provider in multiple places
  const instanceId = useId()
  const suggestionsQueryParams = workmapsStateToApiSearchParams(
    { ...state, search: [], storeId: undefined, categories: [], occupations: [] },
    getLatLonBoxFromState({ lat: state.lat, lng: state.lng, mapBounds: state.mapBounds ?? "static" })
  )

  const suggestionsQuery = useQuery({
    queryKey: [
      "searchSuggestions",
      {
        value,
        instanceId,
        searchParams: suggestionsQueryParams.toString(),
      },
    ] as const,
    queryFn: async ({ signal, queryKey: [, { value, searchParams }] }) => {
      if (!value) {
        return []
      }

      const resp = await api.get(`/api/suggest/${value}`, {
        signal,
        searchParams,
      })

      if (!resp.ok) {
        throw new Error("Failed to fetch suggestions")
      }

      const data = await resp.json<
        ApiResponse<{
          suggestions: {
            tag: string
            count: number
          }[]
        }>
      >()

      if (!data.ok) {
        throw data
      }

      return data.suggestions
    },
  })

  return {
    update: setValue,
    results: suggestionsQuery.data,
    query: suggestionsQuery,
  }
}
