import { forwardRef, ReactNode } from "react"
import { Flex } from "@/components/ui/flex"
import { Card, CardContent } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import { Text } from "@/components/ui/text"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { cn } from "@/lib/frontend/shadcn"

interface JobSidebarLoadingSkeletonProps {
  count?: number
  className?: string
  hero?: ReactNode
  title?: ReactNode
}

export const JobSidebarLoadingSkeleton = forwardRef<HTMLDivElement, JobSidebarLoadingSkeletonProps>(
  ({ count = 10, className, hero, title }, ref) => {
    return (
      <Flex
        ref={ref}
        direction="col"
        gap="sm"
        className={cn(
          "bg-[#f8f9fa] h-full row-start-2 col-start-1 row-end-2 col-end-1 md:row-start-2 md:row-end-2 overflow-auto z-30 p-2.5",
          className
        )}
        aria-busy="true"
      >
        {title && (
          <div>
            <Skeleton className={cn("mt-1", "h-2", "w-1/2")} />
          </div>
        )}
        {hero}
        {Array(count)
          .fill(0)
          .map((_, index) => (
            <JobCardSkeleton key={index} />
          ))}
      </Flex>
    )
  }
)

JobSidebarLoadingSkeleton.displayName = "JobSidebarLoadingSkeleton"

interface JobCardSkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  employerTitle?: string
  logoUrl?: string
}

export const JobCardSkeleton = forwardRef<HTMLDivElement, JobCardSkeletonProps>(
  ({ className, employerTitle, logoUrl, ...props }, ref) => {
    return (
      <Flex direction="col" className={cn("gap-2.5")}>
        <Card ref={ref} align="center" className={cn("text-left rounded-[20px]", className)} {...props}>
          <CardContent direction="row" fullWidth className={cn("gap-4")}>
            <Flex>
              <Avatar className={cn("w-10", "h-10")}>
                <AvatarImage src={logoUrl} />
                <AvatarFallback>
                  <Skeleton className={cn("w-10", "h-10", "rounded-full")} />
                </AvatarFallback>
              </Avatar>
            </Flex>
            <Flex direction="column" className="w-full" gap="sm">
              {employerTitle ? (
                <Text className={cn("max-xss:text-xs", "text-sm")} weight="semibold" lineClamp={1}>
                  {employerTitle}
                </Text>
              ) : (
                <Skeleton className={cn("h-4", "w-3/5", "founded-full")} />
              )}
              <Skeleton className={cn("h-2.5", "w-4/5", "rounded-full")} />
              <Skeleton className={cn("h-2.5", "w-2/3", "rounded-full")} />
              <Skeleton className={cn("h-2.5", "w-1/3", "rounded-full")} />
              <Skeleton className={cn("h-6", "w-full", "rounded-full")} />
            </Flex>
          </CardContent>
        </Card>
      </Flex>
    )
  }
)

JobCardSkeleton.displayName = "JobCardSkeleton"
