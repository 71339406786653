"use client"

import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { useLocationSearch } from "@/lib/frontend/hooks/useLocationSearch"
import { useSearch } from "@/lib/frontend/hooks/useSearch"
import { useForm } from "react-hook-form"
import { IconSearch, IconLocation } from "@tabler/icons-react"
import { useUpdateEffect } from "@react-hookz/web"
import Link from "next/link"
import { cn } from "@/lib/frontend/shadcn"
import { Button } from "./ui/button"
import { Flex } from "./ui/flex"
import { Text } from "./ui/text"
import { Autocomplete as TailwindAutocomplete } from "./ui/autocomplete"
import { useCurrentUrl } from "@/lib/frontend/hooks"
import { AutocompleteSearchInput } from "@/components/whatwhere/AutocompleteSearchInput"
import { MapState } from "@/types"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"

// const LoginPage = dynamic(() => import("./LoginPage").then((mod) => mod.LoginPage), { ssr: false })

export type FormState = Pick<MapState, "search" | "categories" | "payMin"> & {
  address?: string | null
  place_id?: string | null
}

export type WhatWhereHeaderProps = {
  initialState: MapState
  isLoading?: boolean
  initialLocation?: string
  className?: string
}

export const WhatWhereHeader = ({ initialLocation, className }: WhatWhereHeaderProps) => {
  // const [isSidebarOpened, { open: openSidebar, close: closeSidebar }] = useDisclosure(false)
  const { state, dispatch } = useWorkmapsContext()
  const savedAddress = localStorage?.getItem("address") ?? ""
  const savedPlaceId = localStorage?.getItem("place_id") ?? ""
  const [whatPlaceholder, wherePlaceholder] = ["are you looking for?", "are you located?"]
  const { refineYourLocation } = useStoreFeedLayer()

  const currentUrl = new URL(useCurrentUrl())

  const form = useForm<FormState>({
    defaultValues: {
      // Upon landing on a slug page, which will populate state.search, we want to present the user an empty search box
      // such that when they enter a new search term, all of it resets and it's super clean.
      search: [],
      categories: state.categories,
      address: savedAddress,
      place_id: savedPlaceId,
      payMin: state.payMin,
    },
  })

  const search = useSearch({ form: form as any })
  const locationSearch = useLocationSearch({
    form: form as any,
    initialAddress: initialLocation,
    uiPlacement: "what-where-header-location-search",
  })
  // const user = useUser()

  // Keep the form state in sync with the context state
  useUpdateEffect(() => {
    form.setValue("search", state.search || [])
  }, [state.search])

  const searchLabel = (
    <>
      <IconSearch className={cn("h-4 min-w-4 text-[#9ca3af] mt-0.5 block sm:hidden")} />
      <Text size="sm" weight="semibold" className={cn("mt-0.5 max-sm:hidden")} asChild>
        <label htmlFor="search">What</label>
      </Text>
    </>
  )

  return (
    <header className={cn(className)}>
      {/* {isSidebarOpened && <LoginPage opened={isSidebarOpened} onClose={closeSidebar} />} */}
      <Flex className={cn("py-2.5 px-2.5 justify-between gap-2 items-center")}>
        <Flex gap="sm" align="center">
          <Link
            href={currentUrl.pathname.startsWith("/all-jobs") ? currentUrl.pathname : "/"}
            replace
            onClick={(e) => {
              // In order to clear the search by clicking on the logo on all jobs pages, we must prevent the event and
              // dispatch to clear the search
              if (currentUrl.pathname.startsWith("/all-jobs") || currentUrl.pathname === "/") {
                e.preventDefault()
                dispatch({ search: [], categories: [], payMin: null, jobCategories: [] })
              }
            }}
          >
            <img
              src="/images/logo.svg"
              alt="Workmaps logo"
              className={cn("object-contain h-10 object-left")}
              width={193}
              height={40}
            />
          </Link>
          <form
            onSubmit={search.onSubmit}
            className={cn(
              "flex items-center pr-2.5 focus-within:shadow-md focus-within:border-primary border rounded-sm"
            )}
          >
            <AutocompleteSearchInput
              search={search}
              label={searchLabel}
              className={cn(
                "border-transparent focus-within:border-transparent sm:w-72 pl-2.5 [&>input]:ml-0 [&>label]:mr-2",
                refineYourLocation() && "sm:w-96"
              )}
              placeholder={whatPlaceholder}
              onInputChange={(v) => form.setValue("search", v.split(","))}
            />
            <Flex className={cn("items-center hidden md:flex", refineYourLocation() && "hidden")}>
              <div className={cn("inline-block rounded-full h-7 w-0.5 self-center bg-input")}></div>

              <TailwindAutocomplete
                leftSection={
                  <Text asChild size="sm" weight="semibold" className="mt-0.5 mx-2">
                    <label htmlFor="address-search">Where</label>
                  </Text>
                }
                id="address-search"
                placeholder={wherePlaceholder}
                options={[
                  {
                    label: "Share Location",
                    value: "share",
                    autocompleteLabel: (
                      <>
                        <IconLocation size={12} />
                        <span>Share Location</span>
                      </>
                    ),
                    submittedInputLabel: "Current Location",
                  },
                  ...(locationSearch.addressQuery.data ?? []),
                ]}
                onInputChange={locationSearch.onAddressSearch}
                onValueChange={({ value }) => {
                  if (value === "share") {
                    return locationSearch.browserGeolocation.getPosition()
                  }

                  locationSearch.onOptionSubmit(value)
                }}
                onClear={locationSearch.onAddressClear}
                isLoading={locationSearch.addressQuery.isFetching}
                inputClassName="border-transparent focus-within:border-transparent sm:w-72"
                defaultValue={initialLocation}
                forceOpenOnFocus
              />
            </Flex>
            <Button type="submit" size="sm" className={cn("flex-[0] max-sm:hidden")}>
              Search
            </Button>
          </form>
        </Flex>

        {/* <Flex className={cn("gap-2 items-center")}>
          <Tooltip delayDuration={0}>
            <TooltipTrigger className={cn("text-inherit ml-1")} asChild>
              {user?.picture ? (
                <Button onClick={openSidebar} variant="outline" className={cn("h-9 w-9")}>
                  <Avatar className={cn("h-9 w-9")}>
                    <AvatarImage src={user.picture} alt="Profile picture" />
                    <AvatarFallback>
                      <IconUserCircle color="gray" className="overflow-visible" />
                    </AvatarFallback>
                  </Avatar>
                </Button>
              ) : (
                <Button onClick={openSidebar} className={cn("h-9 w-9")} variant="secondary">
                  <IconUserCircle color="gray" className="overflow-visible" />
                </Button>
              )}
            </TooltipTrigger>
            <TooltipContent side="bottom">User Profile</TooltipContent>
          </Tooltip>
        </Flex> */}
      </Flex>
    </header>
  )
}
