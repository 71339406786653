import { MapState } from "@/types"

export const appliedFiltersCount = (state: MapState) => {
  let count = 0

  if (state.payMin && state.payMin > 0) {
    count += 1
  }

  if (state.jobCategories.length > 0) {
    count += state.jobCategories.length
  }

  // if ((state.feeds ?? [])?.length > 0) {
  //   count += (state.feeds ?? []).length
  // }

  return count
}

export const isFilter = (state: MapState): boolean => {
  return (
    (state.payMin && state.payMin > 0) || state.jobCategories.length > 0
    // || (state.feeds ?? [])?.length > 0
  )
}
