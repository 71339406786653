import { forwardRef, ReactNode } from "react"
import { Flex } from "@/components/ui/flex"
import { Card, CardHeader, CardContent } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import { Text } from "@/components/ui/text"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { cn } from "@/lib/frontend/shadcn"

interface StoresSidebarLoadingSkeletonProps {
  count?: number
  className?: string
  hero?: ReactNode
  title?: ReactNode
}

export const StoresSidebarLoadingSkeleton = forwardRef<HTMLDivElement, StoresSidebarLoadingSkeletonProps>(
  ({ count = 7, className, hero, title }, ref) => {
    return (
      <Flex
        ref={ref}
        direction="col"
        gap="sm"
        className={cn(
          "bg-[#f8f9fa] h-full row-start-2 col-start-1 row-end-2 col-end-1 md:row-start-2 md:row-end-2 overflow-auto z-30 p-2.5",
          className
        )}
        aria-busy="true"
      >
        {title && (
          <div>
            <Skeleton className={cn("mt-1", "h-2", "w-1/2")} />
          </div>
        )}
        {hero}
        {Array(count)
          .fill(0)
          .map((_, index) => (
            <StoreCardSkeleton key={index} />
          ))}
      </Flex>
    )
  }
)

StoresSidebarLoadingSkeleton.displayName = "StoresSidebarLoadingSkeleton"

interface StoreCardSkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  jobs?: number
  employerTitle?: string
  logoUrl?: string
  loadMoreButton?: boolean
}

export const StoreCardSkeleton = forwardRef<HTMLDivElement, StoreCardSkeletonProps>(
  ({ className, jobs = 2, employerTitle, logoUrl, loadMoreButton, ...props }, ref) => {
    return (
      <Card
        ref={ref}
        className={cn("rounded-2xl", "border-[1px]", "border-[#dee2e6]", "flex", "flex-col", "gap-3", className)}
        {...props}
      >
        <CardHeader className={cn("px-1.5", "py-2.5")}>
          <Flex gap="md">
            <Avatar className={cn("w-10", "h-10")}>
              <AvatarImage src={logoUrl} />
              <AvatarFallback>
                <Skeleton className={cn("w-10", "h-10", "rounded-full")} />
              </AvatarFallback>
            </Avatar>
            <Flex direction="col" gap="sm" className={cn("mt-1", "flex-[1]")}>
              {employerTitle ? (
                <Text className={cn("max-xss:text-xs", "text-sm")} weight="semibold" lineClamp={1}>
                  {employerTitle}
                </Text>
              ) : (
                <Skeleton className={cn("h-2.5", "w-[60%]")} />
              )}
              <Skeleton className={cn("h-2.5", "w-[90%]")} />
            </Flex>
          </Flex>
        </CardHeader>
        <CardContent>
          <Flex direction="col" className={cn("gap-2.5")}>
            {Array(jobs)
              .fill(0)
              .map((_, index) => (
                <JobCardSkeleton key={index} />
              ))}
          </Flex>
          {loadMoreButton && (
            <Flex justify="center" align="center" className={cn("w-full", "h-[3.75rem]")}>
              <Skeleton className={cn("h-3", "w-48", "rounded-full")} />
            </Flex>
          )}
        </CardContent>
      </Card>
    )
  }
)

StoreCardSkeleton.displayName = "StoreCardSkeleton"

export const JobCardSkeleton: React.FC = () => (
  <Card align="center" className={cn("text-left")}>
    <CardContent direction="row" fullWidth className={cn("p-1")}>
      <Flex direction="column" fullWidth flex="1" className={cn("gap-2")}>
        <Skeleton className={cn("h-2.5", "w-[90%]")} />
        <Skeleton className={cn("h-2.5", "w-1/3")} />
      </Flex>
      <Skeleton className={cn("h-6", "w-16", "rounded-full")} />
    </CardContent>
  </Card>
)
