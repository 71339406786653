"use client"

import { cn } from "@/lib/frontend/shadcn"
import { Text } from "@/components/ui/text"
import { Flex } from "@/components/ui/flex"
import { Button, ButtonLink } from "@/components/ui/button"
import type { EnrichedJob } from "@/types"
import { useEffect, useRef, useCallback, useState } from "react"
import { NoMatchingJobs } from "@/components/NoMatchingJobs"
import { useParams } from "@/components/ParamsProvider"
import { JobPage } from "./JobPage"
import { useMap } from "@/components/MapProvider"
import { useIsMedium } from "@/lib/frontend/util"
import { SlugHeroDetailsProps, SlugHeroDetails } from "@/components/SlugHeroDetails"
import { JobCard } from "./JobCard"
import { NoOpErrorBoundary } from "../ErrorBoundaries"
import { useElementsRef, useWorkmapsContext } from "@/lib/frontend/hooks"
import { JobSidebarLoadingSkeleton } from "./JobsSidebarLoadingSkeleton"
import { useIntersectionObserver } from "@react-hookz/web"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"

export interface JobsSidebarProps {
  jobs: EnrichedJob[]
  selectedStoreId?: string
  selectedJobId?: string
  title?: React.ReactNode
  isLoadingStores?: boolean
  slugHeroDetails?: SlugHeroDetailsProps | null
  relatedSlugs?: Array<{ title: string; slug: string; icon: string | null }>
  onJobClick?: (job: EnrichedJob, setStoreId?: boolean) => void
  onJobClose?: () => void
  className?: string
  search?: string[]
  zoom?: number
}

export const JobsSidebar: React.FC<JobsSidebarProps> = ({
  jobs: allJobs,
  selectedStoreId,
  selectedJobId,
  isLoadingStores,
  title,
  slugHeroDetails,
  relatedSlugs,
  onJobClick,
  onJobClose,
  className,
  search,
  zoom,
}) => {
  const { dispatch } = useWorkmapsContext()
  const jobsListIsFiltered = selectedStoreId && allJobs.filter((job) => job.store_id === selectedStoreId).length > 1
  const jobs = jobsListIsFiltered ? allJobs.filter((job) => job.store_id === selectedStoreId) : allJobs
  const { mapPinModal } = useStoreFeedLayer()

  // #region SEO and slug hero
  // Manage expand/collapse state for SlugHero in JobSideBar so Virtualizer can measure component height
  // Also necessry to keep the SlugHero collapse while scrolling otherwise virtualizer will we-open it
  const [slugHeroExpanded, setSlugHeroDetailExpansion] = useState(true)
  const toggleSlugHeroDetail = () => {
    setSlugHeroDetailExpansion((curr) => !curr)
  }

  // Build a Title for SlugPage when not using HeroDetails
  const [searchParams] = useParams()
  const utmTermValue = searchParams.get("utm_term")
  const nearMeLower = "near me"

  if (utmTermValue) {
    title = utmTermValue.toLowerCase().includes(nearMeLower) ? utmTermValue : `${utmTermValue} ${nearMeLower}`
  }

  // #region Job Page
  // On job card click, we want to animate the job card sliding out. We must decouple this animation from the query
  // parameters changing so it can finish.
  //
  // We set this to true initially so that hot linking to jobs works.
  const [slideOut, setSlideOut] = useState(true)

  const handleJobClick = useCallback<NonNullable<JobsSidebarProps["onJobClick"]>>(
    (job) => {
      onJobClick?.(job, !!jobsListIsFiltered)

      if (mapPinModal()) {
        const jobIndex = jobs
          .filter((storeJob) => storeJob.store_id === job.store_id) // filter specific store
          .findIndex((storeJob) => storeJob.id === job.id) // target index at that specific store
        window.dispatchEvent(
          new CustomEvent("jobCardClicked", {
            detail: {
              storeId: job.store_id,
              jobIndex: jobIndex,
              jobId: job.id,
            },
          })
        )
      }

      setTimeout(() => setSlideOut(true), 50)
    },
    [onJobClick, jobsListIsFiltered, jobs, mapPinModal]
  )

  const handleJobClose = useCallback(() => {
    setSlideOut(false)

    if (onJobClose) {
      setTimeout(() => onJobClose(), 300)
    }
  }, [onJobClose])

  const handleFilteredJobClose = useCallback(() => {
    setSlideOut(false)
    setTimeout(() => dispatch({ job: undefined }), 300)
  }, [dispatch])

  // Disabling job page animation on mobile map view because animation don't play well with it
  const { mapVisible } = useMap()
  const isMedium = useIsMedium()
  const disableAnimationOnMobile = mapVisible && isMedium
  let selectedJobIndex = selectedJobId ? jobs.findIndex((job) => job.id === selectedJobId) : undefined
  if (selectedJobIndex === -1) selectedJobIndex = undefined
  const selectedJob = typeof selectedJobIndex === "number" ? jobs[selectedJobIndex] : undefined
  const showJobPage = !!selectedJob

  // #region Scrolling
  const jobSidebarContainer = useRef<HTMLDivElement>(null)

  // When the selected job scrolls out of view, we must close the job page
  const [jobCards, createJobCardRef] = useElementsRef<HTMLDivElement>()
  const selectedJobSidebarVisibilityObserver = useIntersectionObserver(
    !isMedium && selectedJobId ? jobCards.current?.[selectedJobId] : null,
    {
      root: jobSidebarContainer,
      threshold: [0.0, 1],
    }
  )

  const hideJobPageWhenScrolledOutOfView = useCallback(() => {
    if (
      selectedJobId &&
      !jobsListIsFiltered &&
      selectedJobSidebarVisibilityObserver &&
      !selectedJobSidebarVisibilityObserver.isIntersecting
    ) {
      handleJobClose()
    }
  }, [selectedJobId, selectedJobSidebarVisibilityObserver, handleJobClose, jobsListIsFiltered])

  // If the user is on a slug page and scrolls a bit, show them an alert that
  // they can reset filters to see more jobs.
  const [shownSeeMoreJobsAlert, setShownSeeMoreJobsAlert] = useState(false)
  const handleScroll = useCallback(() => {
    setShownSeeMoreJobsAlert((hasShown) => {
      if (hasShown) return true
      if (
        !jobSidebarContainer.current ||
        window.location.pathname === "/" ||
        window.location.pathname.startsWith("/all-jobs")
      )
        return false

      const scrollableHeight = jobSidebarContainer.current.scrollHeight
      const triggerHeight = 0.1 * scrollableHeight
      return jobSidebarContainer.current.scrollTop > triggerHeight
    })
  }, [jobSidebarContainer])

  useEffect(() => {
    const reactToPinClick = async (event: CustomEvent<{ storeId: string }>) => {
      const storesJobs = jobs.filter((job) => job.store_id === event.detail.storeId)
      if (storesJobs.length === 1) {
        jobCards.current?.[storesJobs[0].id]?.scrollIntoView()
      }
      // when job list is filtered, scrolling into view won't work
      // so we have to look at the larger list of all jobs and wait
      // list to unfitler and then scroll to it. We only want to do
      // this if it's a single job in the list, as the state change
      // above will handle the multi to multi job navigation.
      else if (storesJobs.length === 0) {
        const filteredOutJobs = allJobs.filter((job) => job.store_id === event.detail.storeId)
        if (filteredOutJobs.length === 1) {
          setTimeout(() => {
            jobCards.current?.[filteredOutJobs[0].id]?.scrollIntoView()
          }, 5)
        }
      }
    }

    // @ts-expect-error Custom event listeners have bad types
    window.addEventListener("mapPinClicked", reactToPinClick)
    // @ts-expect-error Custom event listeners have bad types
    return () => window.removeEventListener("mapPinClicked", reactToPinClick)
  }, [jobs, jobCards, allJobs])

  // When the user searches, zooms in, toggles back from map view or clicks on a pin with multiple jobs (creating
  // a filter in the sidebar) we need to scroll to the top of the list.
  const scrollToTopHash =
    (search?.join(" ") ?? " ") +
    (zoom?.toString() ?? " ") +
    mapVisible.toString() +
    (jobsListIsFiltered ? selectedStoreId : " ")
  useEffect(() => {
    if (jobSidebarContainer.current && scrollToTopHash) {
      jobSidebarContainer.current.scrollTop = 0
    }
  }, [scrollToTopHash])

  const heroComponent = slugHeroDetails && (
    <SlugHeroDetails
      {...slugHeroDetails}
      isExpanded={slugHeroExpanded}
      componentExpansionToggle={toggleSlugHeroDetail}
    />
  )

  if (isLoadingStores) {
    return (
      <JobSidebarLoadingSkeleton
        hero={heroComponent}
        title={title && !slugHeroDetails}
        className={cn("row-start-2 md:col-start-2 row-end-2 md:col-end-2 col-span-full", className)}
      />
    )
  }

  return (
    <>
      {showJobPage && !mapPinModal() && (
        <JobPage
          job={selectedJob}
          onClose={jobsListIsFiltered ? handleFilteredJobClose : handleJobClose}
          className={cn(
            "row-start-2 col-start-1 row-end-2 md:row-start-2 overflow-y-auto z-[100] md:z-20 col-end-3 md:col-start-3 lg:w-[450px] w-auto",
            // Animation
            !disableAnimationOnMobile && [
              "transform transition-all ease-in-out duration-300 md:translate-y-0",
              slideOut ? "opacity-100" : "opacity-75",
              {
                // Mobile classes, slides up from the bottom
                "translate-y-0": slideOut,
                "translate-y-full": !slideOut,

                // Desktop classes, slides in from the left
                "md:translate-x-0": slideOut,
                "md:-translate-x-full": !slideOut,
              },
            ]
          )}
        />
      )}
      <Flex
        direction="col"
        className={cn(
          "bg-[#f8f9fa] h-full row-start-2 col-start-1 col-end- row-end-2 md:row-start-2 md:row-end-2 md:col-start-2 md:col-end-2 overflow-auto z-30",
          className
        )}
      >
        {title && !slugHeroDetails && (
          <Flex direction="row" justify="between" align="center" className={cn("px-2.5", title && "py-3")}>
            <Text asChild size="sm" weight="bold" transform="capitalize" lineClamp={1}>
              <h1>{title}</h1>
            </Text>
          </Flex>
        )}

        {jobsListIsFiltered && (
          <Flex justify="between" align="center" className={cn("py-2.5 px-3", title && !slugHeroDetails && "pt-0")}>
            <Text className={cn("max-xss:text-xs", "text-[#228620]")} weight="semibold" size="xs" lineClamp={1}>
              Current selection: {jobs.length} jobs
            </Text>
            <Button size="sm" className={cn("bg-[#dee2e6] rounded-sm hover:bg-[#dee2e6]/80")} onClick={onJobClose}>
              Reset Selection
            </Button>
          </Flex>
        )}
        <div
          className={cn("w-full", "h-full", "overflow-auto", !title && !jobsListIsFiltered && "py-2.5")}
          ref={jobSidebarContainer}
          onScroll={() => {
            handleScroll()
            hideJobPageWhenScrolledOutOfView()
          }}
        >
          {heroComponent && <div className={cn("py-2.5", jobsListIsFiltered && "pt-0")}>{heroComponent}</div>}
          {jobs.map((job) => {
            return (
              <div
                className={cn("pb-2.5 px-2.5")}
                key={job.id}
                // @ts-expect-error React is really strict about null vs undefined and it truly does not matter here.
                ref={createJobCardRef(job.id)}
              >
                <NoOpErrorBoundary>
                  <JobCard
                    key={job.id}
                    job={job}
                    selected={job.id === selectedJobId}
                    selectedBorder={job.store_id === selectedStoreId}
                    onJobClick={handleJobClick}
                    uiPlacement="jobCardSidebar"
                  />
                </NoOpErrorBoundary>
              </div>
            )
          })}
          <div className={cn("pb-20 sm:pb-5")}>
            <NoMatchingJobs
              text="Can't find what you're looking for? Check out some of our curated job pages near you."
              slugs={relatedSlugs}
            />
          </div>
        </div>
      </Flex>
      <ButtonLink
        replace
        className={cn(
          "fixed",
          "bottom-7",
          "right-7",
          "transition-opacity",
          !shownSeeMoreJobsAlert || mapVisible || showJobPage ? "opacity-0 invisible" : "opacity-100 visible"
        )}
        variant="darkPrimary"
        rounded="lg"
        href="/"
      >
        See more jobs &rarr;
      </ButtonLink>
    </>
  )
}
