"use client"

import { useSearch } from "@/lib/frontend/hooks/useSearch"
import { useForm } from "react-hook-form"
import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { useUpdateEffect } from "@react-hookz/web"
import { FormState } from "./WhatWhereHeader"
import { cn } from "@/lib/frontend/shadcn"
import { Autocomplete as TailwindAutocomplete } from "./ui/autocomplete"
import { useLocationSearch } from "@/lib/frontend/hooks/useLocationSearch"
import { Text } from "./ui/text"
import { Flex } from "./ui/flex"
import { IconSearch, IconMapPin, IconLocation, IconAdjustmentsHorizontal } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { Button } from "./ui/button"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useMap } from "./MapProvider"
import { WhatWhereHeaderProps } from "./WhatWhereHeader"
import { AutocompleteSearchInput } from "./whatwhere/AutocompleteSearchInput"
import { Filters } from "./Filters"
import { appliedFiltersCount, isFilter } from "@/lib/shared/filters"
import { SlugPageResult } from "@/dal/getSlugPage"
import { NoMatchingJobs } from "./NoMatchingJobs"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"

type MobileWhatWhereProps = WhatWhereHeaderProps & {
  currentSlug?: SlugPageResult
  hideFilters?: boolean
}

export const MobileWhatWhere = ({ initialLocation, currentSlug, hideFilters }: MobileWhatWhereProps) => {
  const [openWhatWhere, setOpenWhatWhere] = useState(false)
  const analytics = useAnalytics()
  const { mapVisible } = useMap()
  const { state } = useWorkmapsContext()
  const { defaultSidebarFilters } = useStoreFeedLayer()
  const savedAddress = localStorage?.getItem("address") ?? ""
  const savedPlaceId = localStorage?.getItem("place_id") ?? ""
  const filterCount = appliedFiltersCount(state)
  const isFilterPresent = isFilter(state)

  const [open, setOpen] = useState(false)

  const toggleFilter = () => {
    setOpen(!open)

    if (open) {
      analytics.track("Mobile Filters Closed")
    } else {
      analytics.track("Mobile Filters Opened")
    }
  }

  const form = useForm<FormState>({
    defaultValues: {
      // Upon landing on a slug page, which will populate state.search, we want to present the user an empty search box
      // such that when they enter a new search term, all of it resets and it's super clean.
      search: [],
      categories: state.categories,
      address: savedAddress,
      place_id: savedPlaceId,
      payMin: state.payMin,
    },
  })

  const search = useSearch({ form: form as any })
  const locationSearch = useLocationSearch({
    form: form as any,
    toggle: () => void 0,
    uiPlacement: "what-where-header-location-search",
  })

  // Keep the form state in sync with the context state
  useUpdateEffect(() => {
    form.setValue("search", state.search ?? [])
  }, [state.search])

  const handleOpen = () => {
    setOpenWhatWhere(true)
    analytics.track("MobileWhatWhere Opened")
  }

  const handleClose = () => {
    setOpenWhatWhere(false)
    analytics.track("MobileWhatWhere Closed")
  }

  const [searchButtonPosition, setSearchButtonPosition] = useState("0px")

  // place search button above keyboard when active
  useEffect(() => {
    const preventScroll = (e: Event) => {
      e.preventDefault()
    }

    const handleResize = () => {
      const viewportHeight = window.visualViewport?.height || window.innerHeight
      const windowHeight = window.innerHeight
      const keyboardHeight = windowHeight - viewportHeight

      if (keyboardHeight > 100) {
        setSearchButtonPosition(`${keyboardHeight}px`)
        // reset to top if scroll happens
        window.scrollTo(0, 0)
        window.addEventListener("touchmove", preventScroll, { passive: false })
      } else {
        setSearchButtonPosition("0px")
        window.removeEventListener("touchmove", preventScroll)
      }
    }

    window.visualViewport?.addEventListener("resize", handleResize)
    window.addEventListener("orientationchange", handleResize)
    handleResize()

    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize)
      window.removeEventListener("orientationchange", handleResize)
      window.removeEventListener("touchmove", preventScroll)
    }
  }, [])

  return (
    <>
      <Flex
        className={cn("bg-white z-[200] absolute top-0 h-full w-full lock-body-scroll", !openWhatWhere && "hidden")}
      >
        <form
          onSubmit={(e) => {
            search.onSubmit(e)
            setOpenWhatWhere(false)
          }}
          className={cn("flex flex-col text-black flex-grow h-full")}
        >
          <Flex direction="col" className="flex-shrink-0 bg-white gap-2 px-5 py-3 border-b">
            <Flex justify="between" align="center">
              <Text weight="semibold">Search jobs for you</Text>
              <Button
                className={cn("p-0 font-medium text-muted-foreground")}
                variant="transparent"
                onClick={handleClose}
              >
                Close
              </Button>
            </Flex>
            <div className="relative">
              <Flex className={cn("absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none mt-0.5")}>
                <IconSearch size={16} />
              </Flex>
              <AutocompleteSearchInput
                search={search}
                placeholder="What are you looking for?"
                className={cn("ps-9")}
                onValueChange={() => setOpenWhatWhere(false)}
              />
            </div>
            <div className="relative">
              <Flex className={cn("absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none mt-0.5")}>
                <IconMapPin size={16} />
              </Flex>
              <TailwindAutocomplete
                placeholder="Where are you located?"
                emptyMessage="No results"
                options={[
                  {
                    label: "Share Location",
                    value: "share",
                    autocompleteLabel: (
                      <>
                        <IconLocation size={12} />
                        <span>Share Location</span>
                      </>
                    ),
                    submittedInputLabel: "Current Location",
                  },
                  ...(locationSearch.addressQuery.data ?? []),
                ]}
                onInputChange={locationSearch.onAddressSearch}
                onValueChange={({ value }) => {
                  setOpenWhatWhere(false)

                  if (value === "share") {
                    return locationSearch.browserGeolocation.getPosition()
                  }

                  locationSearch.onOptionSubmit(value)
                }}
                onClear={locationSearch.onAddressClear}
                isLoading={locationSearch.addressQuery.isFetching}
                defaultValue={initialLocation}
                leftSection={""}
                inputClassName="ps-9"
                forceOpenOnFocus
              />
            </div>
          </Flex>
          <Flex direction="col" className="gap-1.5 py-3 flex-grow overflow-y-auto">
            <Text size="sm" weight="semibold" className="px-5">
              Trending
            </Text>
            <NoMatchingJobs
              slugs={currentSlug?.relatedSlugs}
              slugClassName="bg-[#f8f9fa] mx-2 px-2"
              uiPosition="mobile-what-where-trending"
              onSlugClick={() => {
                setOpenWhatWhere(false)
              }}
            />
          </Flex>
          <div
            className={cn("bg-white sticky flex-shrink-0 py-4 px-5 w-screen border-t")}
            style={{ bottom: searchButtonPosition }}
          >
            <Button type="submit" variant="dark" fullWidth>
              Search
            </Button>
          </div>
        </form>
      </Flex>

      <header className={cn("z-10 p-3 border-b w-screen flex items-center gap-2", mapVisible && "border-none")}>
        <Button
          variant="transparent"
          fullWidth
          className={cn(
            "border h-10 rounded-md gap-2 relative z-50 bg-white items-center px-3 justify-start min-w-0",
            mapVisible && "shadow-md"
          )}
          onClick={handleOpen}
        >
          <IconSearch size={16} className="flex-shrink-0" />
          <Text
            size="sm"
            className={cn("text-ellipsis whitespace-nowrap overflow-hidden text-muted-foreground font-normal")}
          >
            <span className={cn(search.form.getValues().search.length !== 0 && "hidden")}>Search Workmaps</span>
            <span className="text-black">
              {search.form.getValues().search && search.form.getValues().search.join(", ")}
            </span>
          </Text>
        </Button>

        <Button
          variant="outline"
          className={cn(
            "flex rounded-md h-10 tabular-nums",
            mapVisible && "shadow-md",
            isFilterPresent && "bg-[#E1FDB9] hover:bg-[#E1FDB9]",
            hideFilters && "hidden"
          )}
          onClick={toggleFilter}
        >
          {filterCount ? `${filterCount} Filter${filterCount > 1 ? "s" : ""}` : <IconAdjustmentsHorizontal size={18} />}
        </Button>
      </header>
      {open && defaultSidebarFilters() && (
        <Filters
          className="bg-white z-[200] absolute top-0 h-full w-full overflow-y-auto pb-8"
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}
