import { StoreCard, StoreCardProps } from "@/components/stores-sidebar/StoreCard"
import type { ApiListJob } from "@/types"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import { cn } from "@/lib/frontend/shadcn"
import { useWorkmapsContext } from "@/lib/frontend/hooks"

interface Props extends StoreCardProps {
  selectedJob?: ApiListJob
  onJobClose?: () => void
}

export const MobileStoreCardPreview: React.FC<Props> = (props) => {
  const { dispatch } = useWorkmapsContext()

  const handleClose = () => {
    dispatch({ storeId: undefined, job: undefined })
  }

  return (
    <Dialog open modal={false}>
      <div className="fixed inset-0 bg-black/20 z-50" onClick={handleClose}></div>
      <DialogContent
        hideCloseButton
        style={{ animation: "none" }}
        className={cn("flex flex-col p-0 w-[90%] rounded-[20px] translate-y-[0%] bottom-6 top-auto max-h-[400px]")}
      >
        <StoreCard {...props} />
      </DialogContent>
    </Dialog>
  )
}
