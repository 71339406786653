import { useQuery, useQueryClient } from "@tanstack/react-query"
import { usePathname, useRouter } from "next/navigation"
import { IJobsCatalogContext } from "../WorkmapsJobsCatalogProvider"
import { useCallback } from "react"
import { useSlug } from "@/lib/frontend/hooks/useSlug"

export default function useJobPageModal(jobId?: string) {
  const queryClient = useQueryClient()
  const currentPathname = usePathname()

  // #region Animation
  // It's very difficult to transition job pages via navigation events. Elementes get removed from the DOM, which is
  // something that cannot be animated, so we must use state + timeouts to allow transitions to happen before
  // navigating. Relying on `useState` is fraught as it gets reset queit unpredictably so we must rely on global state
  // to handle this.
  const { data: navigationButtonPressedDirection } = useQuery<"next" | "previous" | null>({
    queryKey: ["jobPage", "navigationButtonPressedDirection", currentPathname],
    initialData: null,
  })
  const setNavigationButtonPressedDirection = useCallback(
    (direction: "next" | "previous" | null, pathname?: string) => {
      queryClient.removeQueries({
        queryKey: ["jobPage", "navigationButtonPressedDirection"],
        exact: false,
      })

      queryClient.setQueryData<typeof navigationButtonPressedDirection>(
        ["jobPage", "navigationButtonPressedDirection", pathname],
        direction
      )
    },
    [queryClient]
  )
  const { data: closingAnimation } = useQuery<boolean>({
    queryKey: ["jobPage", "closingAnimation", currentPathname],
    initialData: false,
  })
  const startClosingAnimation = () => {
    queryClient.setQueryData<boolean>(["jobPage", "closingAnimation", currentPathname], true)
    setTimeout(() => {
      queryClient.removeQueries({
        queryKey: ["jobPage", "closingAnimation"],
        exact: false,
      })
    }, 205)
  }

  // #region Job Feed Navigation
  const { data: jobCatalog } = useQuery<Pick<IJobsCatalogContext, "jobs" | "stores">>({
    queryKey: ["jobCatalog", "transformed"],
  })
  const job = jobCatalog?.jobs.find((j) => j.id === jobId)
  let nextLink: string | undefined
  let previousLink: string | undefined

  if (jobCatalog && jobId) {
    const currentIndex = jobCatalog.jobs.findIndex((j) => j.id === jobId)
    const nextJob = jobCatalog.jobs[currentIndex + 1]
    const prevJob = jobCatalog.jobs[currentIndex - 1]

    if (nextJob) {
      nextLink = `/job/${nextJob.slug}`
    }

    if (prevJob) {
      previousLink = `/job/${prevJob.slug}`
    }
  }

  // #region Closing The Job Modal
  // We have to navigate the user back to the previous slug page they were on, not the last page they visited
  const router = useRouter()
  const slug = useSlug()
  const closePathname = "/" + (!!slug && slug?.slug !== "index" ? slug.slug : "")
  const close = () => {
    startClosingAnimation()
    setNavigationButtonPressedDirection(null)

    setTimeout(() => {
      if (closePathname) {
        router.push(closePathname)
      } else {
        router.back()
      }
    }, 200)
  }

  return {
    job,
    close,
    closePathname,
    closingAnimation,
    startClosingAnimation,
    nextLink,
    previousLink,
    navigationButtonPressedDirection,
    onNavigationButtonClick: setNavigationButtonPressedDirection,
  }
}
