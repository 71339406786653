import { cn } from "@/lib/frontend/shadcn"
import { Card, CardContent } from "../ui/card"
import { Text } from "@/components/ui/text"
import { Flex } from "@/components/ui/flex"
import { Badge } from "@/components/ui/badge"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { EnrichedJob } from "@/types"
import { AppcastApplyLink } from "@/components/AppcastApplyLink"
import { decimalToNumber, payRangeTextContent } from "@/lib/util"
import React, { useCallback } from "react"
import { EmployerLogo } from "@/components/EmployerLogo"
import { IconDiscountCheck } from "@tabler/icons-react"
import { uniqueJobCategories } from "@/lib/shared/categories"
import { useDebugFlags } from "@/lib/frontend/hooks"
import { debugFeedColorsClassnames } from "@/lib/shared/feeds"

export type JobCardProps = {
  job: EnrichedJob
  uiPlacement: string
  selected?: boolean
  selectedBorder?: boolean
  onJobClick?: (job: EnrichedJob) => void
}

export const JobCard = React.forwardRef<HTMLDivElement, JobCardProps>(
  ({ job, selected, selectedBorder, uiPlacement, onJobClick }, ref) => {
    const jobCategories = uniqueJobCategories(job.store)
    const { debug_feed_colors } = useDebugFlags()

    const handleJobClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        return onJobClick?.(job)
      },
      [onJobClick, job]
    )

    return (
      <Card
        role="button"
        className={cn(
          "flex",
          "cursor-pointer",
          "text-left",
          "p-2.5",
          "rounded-[20px]",
          debug_feed_colors && debugFeedColorsClassnames({ source: job.source }),
          !selected && "hover:bg-[rgba(0.1,0.1,0.1,0.05)]",
          selected && "bg-[#E1FDB9]",
          selectedBorder && "border-[#228620]"
        )}
        onClick={handleJobClick}
        asChild
        ref={ref}
        title={debug_feed_colors ? `Source: ${job.source}` : undefined}
      >
        <CardContent className={cn("gap-4 flex-row")}>
          <Flex justify="start" wrap="wrap" gap="md">
            <EmployerLogo employerTitle={job.store.employer.title} employerLogoUrl={job.store.employer.logo_url} />

            {job.verified && (
              <IconDiscountCheck
                size={24}
                color="white"
                fill="#4dabf7"
                className={cn("ml-[-30px]", "mr-[-20px]", "mt-[24px]", "z-20")}
              />
            )}
          </Flex>
          <Flex direction="col" className="w-full overflow-hidden" gap="xs">
            {(job.urgent || debug_feed_colors) && (
              <Flex gap="xs">
                {job.urgent && (
                  <Badge radius="sm" size="xss" className={cn("bg-[#69AE59] text-white border-none")}>
                    Urgently Hiring
                  </Badge>
                )}
                {debug_feed_colors && (
                  <Badge
                    radius="sm"
                    size="xss"
                    className={cn(
                      "text-white border-none",
                      debugFeedColorsClassnames({ source: job.source, badge: true })
                    )}
                  >
                    {job.source}
                  </Badge>
                )}
              </Flex>
            )}
            <Flex direction="col" gap="xs">
              <Text className="max-xss:text-xs" weight="bold">
                {job.title}
              </Text>

              <Text size="sm" className="max-xss:text-xs" weight="medium" color="muted">
                {job.store.employer.title}
              </Text>
              <Text size="sm" className="max-xss:text-xs" weight="medium" color="muted" lineClamp={1}>
                {job.store.title}
              </Text>
              {!!job.pay_min && (
                <Text className={cn("max-xss:text-xs", "text-[#228620]")} size="sm" weight="medium" lineClamp={1}>
                  {job.pay_min || job.pay_max
                    ? payRangeTextContent(decimalToNumber(job.pay_min), decimalToNumber(job.pay_max))
                    : undefined}
                  {job.pay_estimated && (
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger className={cn("text-inherit ml-1")}>(Est.)</TooltipTrigger>
                      <TooltipContent side="bottom">Estimated Pay</TooltipContent>
                    </Tooltip>
                  )}
                </Text>
              )}
              <Flex
                className={cn(
                  "whitespace-nowrap gap-2 no-scrollbar overflow-x-scroll pb-1",
                  job.job_categories.length === 0 && "hidden"
                )}
              >
                {jobCategories.map((jobCategory) => (
                  <Badge
                    key={jobCategory.value}
                    size="xs"
                    outlined
                    className={cn("text-gray-800 border-gray-200 font-medium bg-muted")}
                  >
                    {jobCategory.label}
                  </Badge>
                ))}
              </Flex>
            </Flex>
            <AppcastApplyLink
              job={job}
              uiPlacement={uiPlacement}
              onClickTrackingParams={{
                jobIsInDifferentBadgeTest: job.urgent,
              }}
            />
          </Flex>
        </CardContent>
      </Card>
    )
  }
)
JobCard.displayName = "JobCard"
